import Authenticated from "../Layout/Authenticated";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, url as urlLink, axios } from "../axios";
import Pagination from "@mui/material/Pagination";
import { InfinitySpin } from "react-loader-spinner";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { format, formatDate } from "date-fns";
import {
  MenuItem,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { isMobile } from "react-device-detect";

const rows = [
  {
    id: 1,
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const user = useSelector((state) => state.user);
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "ID",
    },
    {
      id: "udyam_id",
      numeric: false,
      disablePadding: false,
      label: "UDYAM ID",
    },
    {
      id: "owner_name",
      numeric: false,
      disablePadding: false,
      label: "Owner Name",
    },
    {
      id: "name_of_enterprise",
      numeric: false,
      disablePadding: false,
      label: "Name of Enterprise",
    },
    {
      id: "mobile",
      numeric: false,
      disablePadding: false,
      label: "Owner Mobile",
      sortable: false,
    },
    {
      id: "registered_date",
      numeric: false,
      disablePadding: false,
      label: "Registration",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    // {
    //   id: "action",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Action",
    // },
  ];

  const is_m_and_e = user?.roles?.map((e) => e.name).includes("m_and_e");
  if (is_m_and_e) {
    headCells.push({
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Action",
    });
  }
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export default function AllUdyamPage({ isOpen, isClose }) {
  const user = useSelector((state) => state.user);
  const is_m_and_e = user?.roles?.map((e) => e.name).includes("m_and_e");
  const [is_seen, set_is_seen] = useState(false);
  const [remarkInput, setRemarkInput] = useState("");
  const [udyamNo, setUdyamNo] = useState("");
  const [address, setAddress] = useState("");
  const [ownerName, setOwnerName] = useState("");

  const [query, setQuery] = useState("");
  const [selectedDistrict, setselectedDistrict] = useState("");
  const [tempData, setTempData] = useState({});
  const [isBusy, setIsbusy] = useState(true);

  const [entities, setEntities] = useState({
    data: [],
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");

  const submit = (e) => {
    e.preventDefault();
    setIsbusy(true);
    setModal(false);
    let form = {
      entity_id: tempData.id,
      remarks: tempData.remarks,
      is_checked: tempData.is_checked,
      requestEditData: tempData,
    };

    if (tempData.submitEdit === true) {
      form.requestEdit = true;
    }
    setIsbusy(true);
    api
      .post("/api/check", form)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {})
      .finally(() => {
        getData(page);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseModal = () => {
    setModal(false);
  };

  const handleAddRemark = (remarkInit, id) => {
    const form = {
      remarkable_id: id,
      remarkable_type: "App\\Models\\EditModel",
      remarks: remarkInit,
      user_id: user?.id,
    };
    console.log(form);
    api
      .post("/api/remark", form)
      .then((res) => {
        console.log(res.data);
        const temp_data = tempData;
        temp_data.remarks.push(res.data);
        setTempData({});
        setTimeout(() => {
          setRemarkInput("");
          window.scrollTo(document.getElementById("remark_input"));
          setTempData(temp_data);
        }, 100);
      })
      .catch((err) => console.log(err));
  };

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Handle request sort event
   *
   * @param {object} event - event object
   * @param {string} property - the property to sort by
   */

  /******  6821e391-b75b-478d-8cca-696fae4cd058  *******/
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, data) => {
    console.log(data);
    event.stopPropagation();
    let dataTemp = data;
    dataTemp = JSON.parse(JSON.stringify(dataTemp));
    dataTemp.model_data_before = JSON.parse(dataTemp.model_data_before);
    dataTemp.model_data = JSON.parse(dataTemp.model_data);
    setTempData(dataTemp);
    console.log(dataTemp);
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const openCheckModal = (e, row) => {
    e.stopPropagation();
    setTempData({ ...row, is_checked: 0 });
    setOpen(false);
    setModal(true);
  };

  const district = [
    { id: 1, name: "Aizawl" },
    { id: 2, name: "Mamit" },
    { id: 3, name: "Lunglei" },
    { id: 4, name: "Champhai" },
    { id: 5, name: "Kolasib" },
    { id: 6, name: "Serchhip" },
    { id: 7, name: "Saiha" },
    { id: 8, name: "Lawngtlai" },
    { id: 9, name: "" },
    // More users...
  ];

  const navigate = useNavigate();

  const filteredDistrict =
    query === ""
      ? district
      : district.filter((district) => {
          return district.name.toLowerCase().includes(query.toLowerCase());
        });

  const getData = async (page) => {
    setIsbusy(true);
    const url = new URL(`${urlLink}/api/editModel`);

    page && url.searchParams.append("page", page);
    address && url.searchParams.append("address", address);
    startDate && url.searchParams.append("startDate", startDate);
    endDate && url.searchParams.append("startDate", endDate);
    url.searchParams.append("is_seen", is_seen);
    udyamNo && url.searchParams.append("udyam_no", udyamNo);
    selectedDistrict &&
      url.searchParams.append("district", selectedDistrict.toLowerCase());
    axios
      .get(url)
      .then((response) => {
        setEntities(response.data);
        setPage(response.current_page);
      })
      .catch((err) => {
        if (err.status === 401) {
          window.alert("You are not authorized to access this page");
          window.location = "/login";
        }
      })
      .finally(() => {
        setIsbusy(false);
      });

    return 0;
  };

  const handleRemoveField = async (field, index) => {
    setTempData({
      ...tempData,
      [field]: tempData[field].filter((_, i) => i !== index),
    });
  };

  const approve = (event, directives, id) => {
    event.stopPropagation();
    setIsbusy(true);
    const rand = String(Math.round(Math.random() * 10000));

    // if (true || prompt(`Please enter the number ${rand} to confirm`) === rand)
    if (rand) {
      setIsbusy(true);
      api
        .post(`api/editModel/${id}`, {
          _method: "patch",
          is_approved: directives,
        })
        .then((res) => {
          setTempData({});
          getData(page);
          console.log(res);
          setOpen(false);
        })
        .finally(() => {
          setIsbusy(false);
        });
    } else {
      window.alert("You have entered the wrong number");
    }
  };
  const revert = (event, directives, id) => {
    event.stopPropagation();
    setIsbusy(true);
    const rand = String(Math.round(Math.random() * 10000));

    // if (true || prompt(`Please enter the number ${rand} to confirm`) === rand)
    if (rand) {
      setIsbusy(true);
      api
        .get(`api/editModel/revert/${id}`)
        .then((res) => {
          setTempData({});
          getData(page);
          console.log(res);
          setOpen(false);
        })
        .finally(() => {
          setIsbusy(false);
        });
    } else {
      window.alert("You have entered the wrong number");
    }
  };

  const handleAddField = (field) => {
    setTempData({
      ...tempData,
      [field]: tempData[field].concat({}),
    });
    console.log(tempData[field]);
  };
  const createNewUdyam = () => {
    console.log("here");

    setTempData({
      nics: [],
      checks: [],
      bank_detail: {},
      official_address: {},
      employment_detail: {
        male: 0,
        female: 0,
        other: 0,
      },
      units: [],
    });
    setModal(true);
  };

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <Authenticated>
      <div className="px-4 sm:px-6 lg:px-8 p-3 overflow-hidden relative">
        <Dialog
          maxWidth="lg"
          fullWidth
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className=" sm:flex sm:flex-row items-center justify-center w-full">
              <div className="text-lg font-semibold">
                Udyam Registration Number :
              </div>
              <div className="text-lg font-light ml-3">
                {tempData.editable?.udyam_id}
              </div>
            </div>
          </DialogTitle>
          <div className="px-8 xl:px-16 ">
            <div className="mt-3 text-center sm:mt-5 ">
              <div className="border p-3 rounded-md">
                {tempData?.model_data_before &&
                  Object.keys(tempData?.model_data_before).filter(
                    (e) =>
                      ![
                        "nics",
                        "investments",
                        "units",
                        "bank_detail",
                        "official_address",
                        "employment_detail",
                      ].includes(e)
                  ).length > 0 && (
                    <div className=" mt-4">
                      <div className="font-bold underline">Udyam Details</div>
                      <div className="grid md:grid-cols-2 gap-3">
                        <div className="border rounded-md p-2 mt-2">
                          Change From
                          {Object.keys(tempData?.model_data_before)
                            .filter(
                              (e) =>
                                ![
                                  "nics",
                                  "investments",
                                  "units",
                                  "bank_detail",
                                  "employment_detail",
                                  "official_address",
                                ].includes(e)
                            )
                            .map((detail) => {
                              return (
                                <div
                                  className="border rounded-md p-2 mt-2"
                                  key={detail}
                                >
                                  <div
                                    className="grid grid-cols-5"
                                    key={detail}
                                  >
                                    <div className="text-left col-span-2">
                                      {detail[0].toUpperCase() +
                                        detail
                                          .slice(1)
                                          .replaceAll("_", " ")}{" "}
                                    </div>
                                    <div className="text-left  font-normal col-span-3">
                                      {tempData?.model_data_before?.[detail]}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        <div className="border rounded-md p-2 mt-2">
                          Change To
                          {Object.keys(tempData?.model_data)
                            .filter(
                              (e) =>
                                ![
                                  "nics",
                                  "investments",
                                  "units",
                                  "bank_detail",
                                  "employment_detail",
                                  "official_address",
                                ].includes(e)
                            )
                            .map((detail) => {
                              return (
                                <div
                                  className="border rounded-md p-2 mt-2"
                                  key={detail}
                                >
                                  <div
                                    className="grid grid-cols-5"
                                    key={detail}
                                  >
                                    <div className="text-left col-span-2">
                                      {detail[0].toUpperCase() +
                                        detail
                                          .slice(1)
                                          .replaceAll("_", " ")}{" "}
                                    </div>
                                    <div className="text-left col-span-3 font-normal">
                                      {tempData?.model_data?.[detail]}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  )}

                {tempData?.model_data_before?.units.length > 0 && (
                  <div className=" mt-4">
                    {/* Units */}
                    <div className="font-bold underline">Units</div>
                    <div className="grid md:grid-cols-2 gap-3">
                      <div className="border rounded-md p-2 mt-2">
                        Change From
                        {tempData?.model_data_before?.units?.map((unit) => {
                          return (
                            <div
                              className="border rounded-md p-2 mt-2"
                              key={unit.id}
                            >
                              {Object.keys(unit)
                                .filter(
                                  (e) =>
                                    ![
                                      "created_at",
                                      "deleted_at",
                                      "updated_at",
                                      "entity_id",
                                    ].includes(e)
                                )
                                .map((key) => {
                                  return (
                                    <div className="grid grid-cols-3" key={key}>
                                      <div className="text-left">
                                        {key[0].toUpperCase() + key.slice(1)}{" "}
                                      </div>{" "}
                                      <div className="text-left col-span-2 font-normal">
                                        {unit[key]}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        })}
                      </div>
                      <div className="border rounded-md p-2 mt-2">
                        Change To
                        {tempData?.model_data?.units?.map((unit) => {
                          return (
                            <div
                              className="border rounded-md p-2 mt-2"
                              key={unit.id}
                            >
                              {Object.keys(unit).map((key) => {
                                return (
                                  <div className="grid grid-cols-3" key={key}>
                                    <div className="text-left">
                                      {key[0].toUpperCase() + key.slice(1)}{" "}
                                    </div>{" "}
                                    <div className="text-left col-span-2 font-normal">
                                      {unit[key]}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {tempData?.model_data_before?.investments.length > 0 && (
                  <div className=" mt-4">
                    {/* Units */}
                    <div className="font-bold underline">Investments</div>
                    <div className="grid md:grid-cols-2 gap-3">
                      <div className="border rounded-md p-2 mt-2">
                        Change From
                        {tempData?.model_data_before?.investments?.map(
                          (unit) => {
                            return (
                              <div
                                className="border rounded-md p-2 mt-2"
                                key={unit.id}
                              >
                                {Object.keys(unit)
                                  .filter(
                                    (e) =>
                                      ![
                                        "created_at",
                                        "deleted_at",
                                        "updated_at",
                                        "entity_id",
                                      ].includes(e)
                                  )
                                  .map((key) => {
                                    return (
                                      <div
                                        className="grid grid-cols-3"
                                        key={key}
                                      >
                                        <div className="text-left">
                                          {(
                                            key[0].toUpperCase() + key.slice(1)
                                          ).replaceAll("_", " ")}
                                        </div>{" "}
                                        <div className="text-left col-span-2 font-normal">
                                          {key === "is_itr_filled"
                                            ? unit[key]
                                              ? "Yes"
                                              : "No"
                                            : unit[key]}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            );
                          }
                        )}
                      </div>
                      <div className="border rounded-md p-2 mt-2">
                        Change To
                        {tempData?.model_data?.investments?.map((unit) => {
                          return (
                            <div
                              className="border rounded-md p-2 mt-2"
                              key={unit.id}
                            >
                              {Object.keys(unit).map((key) => {
                                return (
                                  <div className="grid grid-cols-3" key={key}>
                                    <div className="text-left">
                                      {(
                                        key[0].toUpperCase() + key.slice(1)
                                      ).replaceAll("_", " ")}
                                    </div>{" "}
                                    <div className="text-left col-span-2 font-normal">
                                      {key === "is_itr_filled"
                                        ? unit[key]
                                          ? "Yes"
                                          : "No"
                                        : unit[key]}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {tempData?.model_data_before?.nics?.length > 0 && (
                  <div className=" mt-4">
                    <div className="font-bold underline">NIC</div>
                    <div className="grid md:grid-cols-2 gap-3">
                      <div className="border rounded-md p-2 mt-2">
                        Change From
                        {tempData?.model_data_before?.nics?.map((unit) => {
                          return (
                            <div
                              className="border rounded-md p-2 mt-2"
                              key={unit.id}
                            >
                              {Object.keys(unit).map((key) => {
                                return (
                                  <div className="grid grid-cols-3" key={key}>
                                    <div className="text-left">
                                      {key[0].toUpperCase() + key.slice(1)}{" "}
                                    </div>{" "}
                                    <div className="text-left col-span-2 font-normal">
                                      {unit[key]}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                      <div className="border rounded-md p-2 mt-2">
                        Change To
                        {tempData?.model_data?.nics?.map((unit) => {
                          return (
                            <div
                              className="border rounded-md p-2 mt-2"
                              key={unit.id}
                            >
                              {Object.keys(unit).map((key) => {
                                return (
                                  <div className="grid grid-cols-3" key={key}>
                                    <div className="text-left">
                                      {key[0].toUpperCase() + key.slice(1)}{" "}
                                    </div>{" "}
                                    <div className="text-left col-span-2 font-normal">
                                      {unit[key]}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {tempData?.model_data_before?.bank_detail &&
                  Object.keys(tempData?.model_data_before?.bank_detail).length >
                    0 && (
                    <div className=" mt-4">
                      <div className="font-bold underline">Bank Details</div>
                      <div className="grid md:grid-cols-2 gap-3">
                        <div className="border rounded-md p-2 mt-2">
                          Change From
                          {Object.keys(
                            tempData?.model_data_before?.bank_detail
                          )?.map((detail) => {
                            return (
                              <div
                                className="border rounded-md p-2 mt-2"
                                key={detail}
                              >
                                <div className="grid grid-cols-3" key={detail}>
                                  <div className="text-left">
                                    {detail[0].toUpperCase() +
                                      detail.slice(1).replace("_", " ")}{" "}
                                  </div>
                                  <div className="text-left col-span-2 font-normal">
                                    {
                                      tempData?.model_data_before?.bank_detail[
                                        detail
                                      ]
                                    }
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="border rounded-md p-2 mt-2">
                          Change To
                          {Object.keys(tempData?.model_data?.bank_detail)?.map(
                            (detail) => {
                              return (
                                <div
                                  className="border rounded-md p-2 mt-2"
                                  key={detail}
                                >
                                  <div
                                    className="grid grid-cols-3"
                                    key={detail}
                                  >
                                    <div className="text-left">
                                      {detail[0].toUpperCase() +
                                        detail.slice(1).replace("_", " ")}{" "}
                                    </div>
                                    <div className="text-left col-span-2 font-normal">
                                      {
                                        tempData?.model_data?.bank_detail[
                                          detail
                                        ]
                                      }
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                {tempData?.model_data_before?.employment_detail &&
                  Object.keys(tempData?.model_data_before?.employment_detail)
                    .length > 0 && (
                    <div className=" mt-4">
                      <div className="font-bold underline">
                        Employee Details
                      </div>
                      <div className="grid md:grid-cols-2 gap-3">
                        <div className="border rounded-md p-2 mt-2">
                          Change From
                          {Object.keys(
                            tempData?.model_data_before?.employment_detail
                          )?.map((detail) => {
                            return (
                              <div
                                className="border rounded-md p-2 mt-2"
                                key={detail}
                              >
                                <div className="grid grid-cols-3" key={detail}>
                                  <div className="text-left">
                                    {detail[0].toUpperCase() +
                                      detail.slice(1).replace("_", " ")}{" "}
                                  </div>
                                  <div className="text-left col-span-2 font-normal">
                                    {
                                      tempData?.model_data_before
                                        ?.employment_detail[detail]
                                    }
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="border rounded-md p-2 mt-2">
                          Change To
                          {Object.keys(
                            tempData?.model_data?.employment_detail
                          )?.map((detail) => {
                            return (
                              <div
                                className="border rounded-md p-2 mt-2"
                                key={detail}
                              >
                                <div className="grid grid-cols-3" key={detail}>
                                  <div className="text-left">
                                    {detail[0].toUpperCase() +
                                      detail.slice(1).replace("_", " ")}{" "}
                                  </div>
                                  <div className="text-left col-span-2 font-normal">
                                    {
                                      tempData?.model_data?.employment_detail[
                                        detail
                                      ]
                                    }
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}

                {tempData?.model_data_before?.official_address &&
                  Object.keys(tempData?.model_data_before?.official_address)
                    .length > 0 && (
                    <div className=" mt-4">
                      <div className="font-bold underline">
                        Official Address
                      </div>
                      <div className="grid md:grid-cols-2 gap-3">
                        <div className="border rounded-md p-2 mt-2">
                          Change From
                          {Object.keys(
                            tempData?.model_data_before?.official_address
                          )?.map((detail) => {
                            return (
                              <div
                                className="border rounded-md p-2 mt-2"
                                key={detail}
                              >
                                <div className="grid grid-cols-3" key={detail}>
                                  <div className="text-left">
                                    {detail[0].toUpperCase() +
                                      detail.slice(1).replace("_", " ")}{" "}
                                  </div>
                                  <div className="text-left col-span-2 font-normal">
                                    {
                                      tempData?.model_data_before
                                        ?.official_address[detail]
                                    }
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="border rounded-md p-2 mt-2">
                          Change To
                          {Object.keys(
                            tempData?.model_data?.official_address
                          )?.map((detail) => {
                            return (
                              <div
                                className="border rounded-md p-2 mt-2"
                                key={detail}
                              >
                                <div className="grid grid-cols-3" key={detail}>
                                  <div className="text-left">
                                    {detail[0].toUpperCase() +
                                      detail.slice(1).replace("_", " ")}{" "}
                                  </div>
                                  <div className="text-left col-span-2 font-normal">
                                    {
                                      tempData?.model_data?.official_address[
                                        detail
                                      ]
                                    }
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="rounded-md mt-5 p-4" style={{ borderWidth: "1px" }}>
              <div>
                Status :{" "}
                {tempData.is_approved && tempData.approver_id
                  ? "Approved"
                  : !tempData.is_approved && tempData.approver_id
                    ? "Rejected"
                    : "Pending"}
              </div>
            </div>
            <div className="rounded-md mt-5 p-4" style={{ borderWidth: "1px" }}>
              <div>Remarks</div>
              {tempData?.remarks?.map((remark) => (
                <div className="" key={remark.id}>
                  <div className="p-2 " style={{ borderWidth: "1px" }}>
                    <div className="text-xs text-gray-500 flex justify-between">
                      <div>{remark.user?.name}</div>
                      <div className="">
                        {format(
                          new Date(remark.created_at),
                          "dd-MMM-yy hh:mm a"
                        )}
                      </div>
                    </div>
                    <div>{remark.remarks}</div>
                  </div>
                </div>
              ))}
              <div className="">
                <div className="mt-4">
                  <TextField
                    label="Add Remark"
                    multiline
                    rows={4}
                    id="remark_input"
                    className="w-full"
                    variant="outlined"
                    value={remarkInput}
                    onChange={(e) => setRemarkInput(e.target.value)}
                  />
                </div>
                <div className="flex justify-center w-full mb-5 gap-x-3 mt-3">
                  <Button
                    variant="contained"
                    onClick={() => handleAddRemark(remarkInput, tempData.id)}
                    autoFocus
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center w-full mb-5 mt-5 gap-x-3">
            {is_m_and_e && tempData.approver_id && (
              <Button
                variant="contained"
                onClick={(e) => {
                  revert(e, true, tempData.id);
                }}
                autoFocus
              >
                Revert
              </Button>
            )}
            {is_m_and_e && !tempData.approver_id && (
              <Button
                variant="contained"
                onClick={(e) => {
                  approve(e, true, tempData.id);
                }}
                autoFocus
              >
                Accept
              </Button>
            )}
            {is_m_and_e && !tempData.approver_id && (
              <Button
                variant="contained"
                color="error"
                onClick={(e) => {
                  approve(e, false, tempData.id);
                }}
                autoFocus
              >
                Reject
              </Button>
            )}
            <Button
              variant="contained"
              color="error"
              onClick={handleClose}
              autoFocus
            >
              Close
            </Button>
          </div>
        </Dialog>

        <div className="">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
            <div className="md:mt-0 mt-10">
              <TextField
                label="Udyam No."
                className="w-full"
                value={udyamNo}
                onChange={(e) => setUdyamNo(e.target.value)}
                size="small"
                variant="outlined"
              />
            </div>
            <div className="md:mt-0 mt-10">
              <TextField
                label="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="w-full"
                size="small"
                variant="outlined"
              />
            </div>
            <TextField
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
              label="Owner Name"
              className="w-full"
              size="small"
              variant="outlined"
            />
          </div>
          <div className="md:mt-10 mt-10 grid grid-cols-1 gap-4 md:grid-cols-6">
            <div className=" w-full md:col-span-1 col-span-2">
              <TextField
                className="w-full"
                select
                size="small"
                id="demo-simple-select"
                value={selectedDistrict}
                label="District"
                onChange={(e) => setselectedDistrict(e.target.value)}
              >
                {district.map((dist) => (
                  <MenuItem key={dist.id} value={dist.name}>
                    {dist.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="md:col-span-1 col-span-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="From Date"
                  onChange={(newValue) =>
                    setStartDate(format(new Date(newValue.$d), "yyyy-MM-dd"))
                  }
                  className="w-full"
                />
              </LocalizationProvider>
            </div>

            <div className="md:col-span-1 col-span-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="To Date"
                  onChange={(newValue) =>
                    setStartDate(format(new Date(newValue.$d), "yyyy-MM-dd"))
                  }
                  className="w-full"
                />
              </LocalizationProvider>
            </div>
            <FormControlLabel
              className="w-full"
              label="Checked"
              control={
                <Checkbox
                  checked={is_seen == true}
                  onChange={(e) => {
                    set_is_seen(e.target.checked);
                  }}
                />
              }
            />
            <div className=" col-span-2 grid grid-cols-2  gap-x-2 mx-3">
              <Button
                variant="contained"
                onClick={() => getData(1)}
                size="small"
              >
                Submit
              </Button>
              <Button variant="contained" color="error" size="small">
                Cancel
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            {isBusy ? (
              <div className="flex justify-center">
                <InfinitySpin
                  visible={true}
                  width="200"
                  color="#4fa94d"
                  ariaLabel="infinity-spin-loading"
                />
              </div>
            ) : (
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                <div className="max-w-full overflow-hidden">
                  <Paper>
                    <TableContainer>
                      <Table
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        {!isMobile && (
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                        )}
                        <TableBody className="w-full">
                          {entities?.data.map((row, index) => {
                            const isItemSelected = selected.includes(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row)}
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                                className={
                                  isMobile ? "border-2 rounded-md" : ""
                                }
                                sx={{
                                  cursor: "pointer",
                                  display: isMobile ? "grid" : "table-row",
                                }}
                              >
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="left"
                                >
                                  <div>{row.id}</div>
                                </TableCell>
                                <TableCell align="left">
                                  <div>{row.editable?.udyam_id}</div>
                                  <div>
                                    {row.editable_type.includes("Udyam")
                                      ? "Post Checked"
                                      : "Pre Checked"}
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  <div>{row?.editable?.owner_name}</div>
                                  <div>{row?.verify?.trashed_unit?.name}</div>
                                </TableCell>

                                <TableCell align="left">
                                  {row?.editable?.name_of_enterprise}
                                </TableCell>
                                <TableCell align="left">
                                  {row?.editable?.mobile}
                                </TableCell>
                                <TableCell align="left">
                                  {row?.editable?.date_of_registration &&
                                    formatDate(
                                      new Date(
                                        row?.editable?.date_of_registration
                                      ),
                                      "dd-MM-yyyy"
                                    )}
                                </TableCell>
                                <TableCell align="left">
                                  {row?.is_approved && row?.approver_id
                                    ? "Approved"
                                    : !row?.is_approved && row?.approver_id
                                      ? "Rejected"
                                      : "Pending"}
                                </TableCell>
                                {is_m_and_e && !row.approver_id && (
                                  <TableCell align="left">
                                    <div className="grid grid-cols-2 gap-x-1">
                                      <Button
                                        variant="contained"
                                        size="small"
                                        className="text-white bg-blue-700 hover:bg-blue-500 w-full rounded-md"
                                        type="button"
                                        onClick={(e) =>
                                          approve(e, true, row?.id)
                                        }
                                      >
                                        Approve
                                      </Button>
                                      <Button
                                        variant="contained"
                                        size="small"
                                        color="error"
                                        className="text-white  hover:bg-red-500 w-full bg-red-500 h-8 rounded-md "
                                        type="button"
                                        onClick={(e) =>
                                          approve(e, false, row?.id)
                                        }
                                      >
                                        Reject
                                      </Button>
                                    </div>
                                  </TableCell>
                                )}
                                {is_m_and_e && row.approver_id && (
                                   <TableCell align="left">
                                  <Button
                                    variant="contained"
                                    onClick={(e) => {
                                      revert(e, true, row.id);
                                    }}
                                    autoFocus
                                  >
                                    Revert
                                  </Button>
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                          {entities?.data.length - entities?.per_page > 0 && (
                            <TableRow
                              style={{
                                height:
                                  (dense ? 33 : 53) *
                                  (entities?.data.length - entities?.per_page),
                              }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className="relative py-3">
                      <div className="mt-2 w-full md:absolute md:left-1/2 md:-translate-x-1/2 flex justify-center z-30">
                        <Pagination
                          count={Math.ceil(entities.total / entities.per_page)}
                          page={page}
                          onChange={(e, number) => getData(number)}
                        />
                      </div>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={entities?.total}
                        rowsPerPage={entities?.per_page}
                        page={entities?.current_page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </Paper>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Authenticated>
  );
}
