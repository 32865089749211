const people = [
  {
    name: "A",
    title: "Cluster Interventions",
    email: "",
    role: "",
    role1: "",
  },
  {
    name: "A1",
    title: "Soft Interventions (thiamna leh theihna tihpun)",
    email: "100%",
    role: "Hard Intervention atanga 10% emaw nuai 25 (a tlem zawk zawk)",
    role1: "Nil",
  },
  {
    name: "A2",
    title: "Hard Interventions including CFCs, RMBs, training centres,etc",
    email: "95%",
    role: "Hard Intervention sum dawn tur zat atanga 5% sum faiin a thawh ve ang",
    role1: "Nil",
  },
  {
    name: "B",
    title: "Cost of TA",
    email: "100%",
    role: " Hard Intervention atanga 6% emaw nuai 18 (a tlem zawk zawk)",
    role1: "Nil",
  },
  {
    name: "C",
    title: "Cost of IA/SPV (CDE rawih man telin)",
    email: "100%",
    role: " Hard Intervention atanga 8% emaw nuai 20 (a tlem zawk zawk)",
    role1: "Nil",
  },
];
export default function Sfurti() {
  const styles = {
    aspectRatio: "801/1036",
    width: "50.0625rem",
    background: "linear-gradient(to top right, #ff80b5, #9089fc)",
    opacity: 0.3,
    clipPath:
      "polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)",
  };
  return (
    <div className="">
      <div className="relative isolate overflow-hidden bg-white py-10 sm:py-10">
        <div
          className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
          aria-hidden="true"
        >
          <div
            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            style={styles}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <p className="text-lg font-semibold leading-8 tracking-tight text-red-600">
              SFURTI
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              SFURTI thil tum
            </h1>
            <ul role="list" className="mt-5 max-w-xl space-y-4 text-gray-600">
              <li className="flex gap-x-3">
                <span>
                  Kuthnathawktuten kutchhuak mawi leh hralh tla tak, daih rei
                  leh tlo zawk an neih theih nan a zirtir.
                </span>
              </li>
              <li className="flex gap-x-3">
                <span>
                  Thil siamchhuah zat a pun theih nan leh a hlutna belhchhah
                  anih theih nan a thawktute pawl (cluster) ang a hmalakna
                  kalpui.
                </span>
              </li>
              <li className="flex gap-x-3">
                <span>
                  Traditional sector tihhmasawn leh kuthnathawktute sum lakluh
                  tihpun a, hna nghet siamsak.
                </span>
              </li>
              <li className="flex gap-x-3">
                <span> Internet hmanga sumdawnna zauh.</span>
              </li>
            </ul>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
            <div className="relative lg:order-last lg:col-span-5">
              <svg
                className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                    width="200"
                    height="200"
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M0.5 0V200M200 0.5L0 0.499983" />
                  </pattern>
                </defs>
                <rect
                  width="100%"
                  height="100%"
                  stroke-width="0"
                  fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)"
                />
              </svg>
              <figure className="border-l border-red-600 pl-3 sm:pl-8">
                <blockquote className="text-sm font-semibold leading-8 tracking-tight text-gray-900">
                  <p className="font-bold mt-8 text-xl">
                    Sum lama puihbawmna (Financial Assistance)
                  </p>
                  <div className="row mt-4 align-items-center">
                    <table id="table1">
                      <tbody>
                        <tr>
                          <th>Type of Cluster</th>
                          <th>Per cluster Budget limit</th>
                        </tr>

                        <tr>
                          <td>Regular Cluster (min. of 50 artisans)</td>
                          <td>Upto ₹2.5Cr</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p className="font-bold mt-8 text-xl">
                    Scheme kalphung/nihphung (Interventions)
                  </p>
                  <ul
                    role="list"
                    className="mt-4 max-w-xl space-y-4 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      <span>
                        Soft Interventioninhrilhhriatna, inzirtirna, infuih
                        phurna leh inrin tawnna siam. Thiamna leh theihna
                        tihpun, hmanrua leh khawl tihchangtlun, hriatna a zauh
                        na tura hmun hrang hrang fan, an kutchhuak tihhmasawn a
                        zawrh tura phochhuah sak, seminar leh workshop buatsaih,
                        adt.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        Hard InterventionCommon Facility Centres siam, Raw
                        Material Banks, ṭha leh mawi zawk siamchhuah theih nana
                        khawl thilte thuam ṭhat, a hlawma dah khawmna
                        buatsaihsak (warehousing facility), training centre,
                        hlutna belhsak leh siam chhuahna lama puihbawm.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        Thematic InterventionBrand Building leh fakna lam uar
                        tura zirtir, e-commerce lama an thil siam chhuah pho
                        chhuahpui, thil ṭha leh changkang zawk kawhhmuh,
                        research leh hmasawnna lam puihbawm.
                      </span>
                    </li>
                    <span className="font-extrabold text-xs">
                      *Hard Intervention ah hian hnathawhna tur (Working
                      Capital) 20% a tih tel theih ang. [Hei hian raw material
                      lakkhawmna, siam chhuahna tura hlawh bituk, electric leh
                      tui man te a huam]
                    </span>
                  </ul>

                  <figure className="mt-10 border-l border-red-600 pl-2 sm:pl-9">
                    <blockquote className="font-semibold text-gray-900">
                      <p>
                        Hrechiang duh tan ahnuaia link tarlanah hian a en theih
                        ang
                        <a
                          href="https://sfurti.msme.gov.in/SFURTI/Home.aspx"
                          className="text-red-600"
                        >
                          https://sfurti.msme.gov.in/SFURTI/Home.aspx
                        </a>
                      </p>
                    </blockquote>
                  </figure>
                </blockquote>
              </figure>
            </div>
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
              <p className="font-bold text-xl">Scheme in a huam chin</p>
              <ul role="list" className="mt-4 max-w-xl space-y-4 text-gray-600">
                <li className="flex gap-x-3">
                  <span>
                    Khadi Industries (la kaih, maimaw hlum, la hlum emaw a
                    inpawlh)
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    Village Industries(cheng nuai 1.5 aia mi pakhat sum thawh a
                    tam loh leh electric khawl hman lohna hmun. Heng industry te
                    hian ramhmul damdawi te, khuai khawi, ramhnuai thil thar te
                    nawi deuhte, be chi hrang hrang, thei leh thlai siam
                    danglamna, thing leh thir chher, thawmhnaw, puan ṭhui leh
                    chei, adt. a huam)
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>Coir Industries(Coconut aṭanga hrui siamchhuah)</span>
                </li>
              </ul>

              <p className="font-bold mt-4">Scheme dil dan kalphung</p>
              <span>
                *DPR ah siam danglam ngai a awm chuan M/oMSME hian thuchhuah a
                siam ang.
              </span>
              <div className="font-semibold text-xl mt-10">
                Category (sumdawnna huang hrang hrangte huamin)
              </div>
              <div className="">
                Loan lak zat chin aṭanga Guarantee (tiamkam) hmuh theih zat
              </div>
              <div className="sm:hidden">
                <div className=" grid grid-cols-1 mt-5">
                  <div className="font-bold text-xl">Micro Enterprises</div>
                  <div className="grid grid-cols-3">
                    <div className="font-semibold">Nuai 5 chin</div>
                    <div className=" font-semibold ml-3">Nuai 50 chin</div>
                    <div className="font-semibold ml-3">Vbc. 5 chin</div>
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="font-extralight">85%</div>
                    <div className="font-extralight ml-3">75%</div>
                    <div className="font-extralight ml-3">75%</div>
                  </div>
                </div>
                <div className=" grid grid-cols-1 mt-5">
                  <div className="font-bold text-xl">
                    North-East huamchhung MSE (Sikkim, UT of Jammu & Kashmir and
                    UT of Ladakh a huam tel)
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="font-semibold">Nuai 5 chin</div>
                    <div className=" font-semibold ml-3">Nuai 50 chin</div>
                    <div className="font-semibold ml-3">Vbc. 5 chin</div>
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="font-extralight">80%</div>
                    <div className="font-extralight ml-5">80%</div>
                    <div className="font-extralight ml-5">75%</div>
                  </div>
                </div>
                <div className=" grid grid-cols-1 mt-5">
                  <div className="font-bold text-xl">
                    Hmeichhia entrepreneurs/SC/ST entrepreneurs/Aspirational
                    District a awm te/ZED certified/Rualban lo(PwD)/MSE promoted
                    by Agniveers
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="font-semibold">Nuai 5 chin</div>
                    <div className=" font-semibold ml-3">Nuai 50 chin</div>
                    <div className="font-semibold ml-3">Vbc. 5 chin</div>
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="font-extralight">85%</div>
                    <div className="font-extralight ml-5">-</div>
                    <div className="font-extralight ml-5">-</div>
                  </div>
                </div>
                <div className=" grid grid-cols-1 mt-5">
                  <div className="font-bold text-xl">
                    Kawng dang hmang a loan la tu
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="font-semibold">Nuai 5 chin</div>
                    <div className=" font-semibold ml-3">Nuai 50 chin</div>
                    <div className="font-semibold ml-3">Vbc. 5 chin</div>
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="font-extralight">75%</div>
                    <div className="ml-5 font-extralight">-</div>
                    <div className=" ml-5 font-extralight">-</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h1 className="text-3xl mb-4 font-bold mt-6">
            State Level Steering Committee (SLSC) Composition
          </h1>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Sl.
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Project Intervention
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Scheme Funding
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Financial Limit
                          <span>
                            (Project khat atan cheng vbc 5 chin (A+B+C))
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          IA pek tur
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {people.map((person) => (
                        <tr key={person.email}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {person.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.title}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.email}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.role}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.role1}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-10 font-extralight text-sm ">
            *Note: IA/SPV hian an thawh ve tur(i.e., 5%) hi NA hriatpuina in 25%
            zelin an pe thei ang.
          </div>
        </div>
      </div>
    </div>
  );
}
