import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { useState, useEffect } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ReactSVG } from "react-svg";
import Contact from "../components/ContactForm";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

export default function Landing() {
  const [open, setOpen] = useState(false);
  const [playing, setPlaying] = useState(true);
  const handleDownload = () => {
    window.open("MEMizBooklet.pdf", "_blank");
  };
  return (
    <>
      <Dialog open={open} onClose={setOpen} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  {/* <DialogTitle
                    as="h3"
                    className="text-base font-semibold text-gray-900"
                  >
                    Ratings
                  </DialogTitle> */}
                  <AudioPlayer
                    autoPlay={true}
                    src="music.mp3"
                    playing={playing}
                    onPlay={(e) => console.log("onPlay")}
                    onPause={(e) => console.log("onPause")}
                    onError={(e) => console.error("error", e)}
                    autoPlayAfterSrcChange={true}
                  />
                  <div className="mt-2"></div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Go Back
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      <header className=" top-0 z-10  sticky">
        <Disclosure as="nav" className="bg-white shadow ">
          <div className="mx-auto max-w-full px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-24 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon
                    aria-hidden="true"
                    className="block h-6 w-6 group-data-[open]:hidden"
                  />
                  <XMarkIcon
                    aria-hidden="true"
                    className="hidden h-6 w-6 group-data-[open]:block"
                  />
                </DisclosureButton>
              </div>
              <div className="flex flex-1 place-items-center justify-evenly sm:items-stretch sm:justify-between">
                <div className="flex flex-shrink-0 items-center ml-10">
                  <img alt="MEMiz" src="logo.webp" className="h-16 w-auto" />
                  <div className="ml-4">
                    <div className="font-semibold text-xl">MSMEs</div>
                    <div className="font-light text-sm">Elevate Mizoram</div>
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-20">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <a
                    href="#home"
                    className="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium text-gray-900"
                  >
                    Home
                  </a>
                  <a
                    href="#schemes"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Schemes
                  </a>
                  <a
                    href="#map"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Roadmap
                  </a>
                  <a
                    href="#msme"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    MSME Info
                  </a>
                  <button
                    onClick={handleDownload}
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    MEMiz Booklet
                  </button>
                  {/* <button
                    onClick={() => setOpen(true)}
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Open
                  </button> */}
                </div>
                <div className="hidden mr-10 sm:ml-6 sm:flex sm:space-x-8 justify-center place-items-center">
                  <a href="login" className="font-medium text-gray-500 text-sm">
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>

          <DisclosurePanel className="sm:hidden">
            <div className="space-y-1 pb-4 pt-2">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <DisclosureButton
                as="a"
                href="#"
                className="block border-l-4 py-2 pl-3 pr-4 text-base font-medium text-red-700"
              >
                Home
              </DisclosureButton>
              <DisclosureButton
                as="a"
                href="#schemes"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-red-700 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Schemes
              </DisclosureButton>
              <DisclosureButton
                as="a"
                href="#map"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-red-700 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Roadmap
              </DisclosureButton>
              <DisclosureButton
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-red-700 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                MSME Info
              </DisclosureButton>
              <DisclosureButton
                as="a"
                onClick={handleDownload}
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-red-700 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                MEMiz Booklet
              </DisclosureButton>
              <DisclosureButton
                as="a"
                href="login"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-red-700 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Login
              </DisclosureButton>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </header>
      <div className="">
        <section id="home">
          <Contact />
        </section>
        {/* <section>
          <div className="w-full justify-center place-items-center">
            <div className="w-9/12">
              <div className=" mb-3 font-semibold">
                I Udaym number enter la i ratings lo hre rawh le
              </div>
              <div className="flex">
                <input
                  id="first-name"
                  name="first-name"
                  type="text"
                  placeholder="Udyam number"
                  autoComplete="given-name"
                  className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                />
                <button
                  onClick={() => setOpen(true)}
                  className="block w-24 ml-5 rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section> */}
        <section id="schemes">
          <div className=" w-full justify-center place-items-center mt-10">
            <img src="scheme.png" className=" justify-center items-center" />
            <div className=" mt-5 text-2xl font-bold">MSME Schemes</div>
            <div className="mt-2 font-light text-center">
              MSME hnuaia Scheme hrang hrang te
            </div>
          </div>
          <div className="grid md:grid-rows-1 grid-rows-3 gap-2 grid-flow-col justify-evenly items-center w-full mt-10">
            <a
              href="/cgstmse"
              className="justify-center place-items-center w-20"
            >
              <img src="1.png" alt="" />
              <div className="text-gray-600 text-xs mt-2">CGTMSE</div>
            </a>
            <a href="/cicss" className="justify-center place-items-center w-20">
              <img src="2.png" alt="" />
              <div className="text-gray-600 text-xs mt-2">CLCSS/SCLCSS</div>
            </a>
            <a
              href="/global"
              className="justify-center place-items-center w-20"
            >
              <img src="3.png" alt="" />
              <div className="text-gray-600 text-xs mt-2">Global Mart</div>
            </a>
            <a
              href="/innovative"
              className="justify-center place-items-center w-20"
            >
              <img src="4.png" alt="" />
              <div className="text-gray-600 text-xs mt-2">Innovative</div>
            </a>
            <a href="/cdp" className="justify-center place-items-center w-20">
              <img src="5.png" alt="" />
              <div className="text-gray-600 text-xs mt-2">MSE-CDP</div>
            </a>
            <a href="/pms" className="justify-center place-items-center w-20">
              <img src="6.png" alt="" />
              <div className="text-gray-600 text-xs mt-2">PMS</div>
            </a>
            <a href="/pmegp" className="justify-center place-items-center w-20">
              <img src="7.png" alt="" />
              <div className="text-gray-600 text-xs mt-2">PMEGP</div>
            </a>
            <a
              href="/sfurti"
              className="justify-center place-items-center w-20"
            >
              <img src="8.png" alt="" />
              <div className="text-gray-600 text-xs mt-2">SFURTI</div>
            </a>

            <a href="/zed" className="justify-center place-items-center w-20">
              <img src="9.png" alt="" />
              <div className="text-gray-600 text-xs mt-2">ZED</div>
            </a>
          </div>
          <div className="flex mt-10 mb-10 justify-center place-items-center w-full">
            <a
              href="https://udyamregistration.gov.in/Government-India/Ministry-MSME-registration.htm"
              className="text-blue-500 hover:text-blue-700 font-semibold"
            >
              UDYAM LINK
            </a>
            <a
              href="https://my.msme.gov.in/MyMsme/Reg/Home.aspx"
              className="text-blue-500 hover:text-blue-700 font-semibold ml-5"
            >
              MSME SCHEME LINK
            </a>
          </div>
          <div className=" mb-10">
            <div className=" mt-10 font-bold text-2xl text-center">
              Scheme chungchanga hriattur
            </div>
            <div className="font-light text-md text-center mt-3 px-5 sm:px-0">
              Heng a chunga scheme-te hi awlsam tea hmeh chhuah theih mai a ni
              a, heng bakah hian Scheme dang tam tak MSME hnuaia awmte dah belh
              zel a ni dawn e.
            </div>
          </div>
        </section>
        <section id="map">
          <div className="hidden sm:block justify-center place-items-center">
            <ReactSVG src="map.svg" />
          </div>
          <div className="sm:hidden justify-center place-items-center">
            <ReactSVG src="map1.svg" />
          </div>
        </section>
        <section id="msme">
          <div className="mb-10">
            <div className=" justify-center place-items-center">
              <img src="logo.webp" alt="" className=" h-64 w-auto" />
              <div className="font-bold text-3xl mt-5">MSME chu enge ni ?</div>
              <div className="font-light text-center text-md mt-2 px-5 sm:px-0">
                MSME chu Micro Small Medium Enterprises lam tawi mai a ni a, chu
                chu India rama Industry tenau zawkte sawi nana hman a ni.
              </div>
            </div>
          </div>
          <div className="grid md:grid-rows-1 grid-rows-3 gap-3 grid-flow-col justify-evenly mb-20 mt-10">
            <div className="w-96 ">
              <div className="font-bold text-2xl text-center">MSME</div>
              <div className=" font-light text-justify text-sm px-5 sm:px-0">
                MSME hian thil siam chhuahna Manufacturing, Services-te bakah
                Retail and Trade-te a huam vek a, a hnuaih hian Sector hrang
                hrang a awm a ni.
              </div>
            </div>
            <div className="w-96 ">
              <div className="font-bold text-2xl text-center">RAMP</div>
              <div className=" font-light text-justify text-sm px-5 sm:px-0">
                Raising and Accelerating MSME Performance (RAMP) chuan India
                rama MSME Sector hrang hrang chawikan hi a tum ber a ni a, chak
                taka kalpui tum a ni.
              </div>
            </div>
            <div className="w-96">
              <div className="font-bold text-2xl text-center">MEMiz</div>
              <div className=" font-light text-justify text-sm px-5 sm:px-0">
                RAMP hi Mizoramah ṭangkai taka kalpui a nih ve theih nan MSME
                Elevate Mizoram (MEMiz) hi din a lo ni ta a ni.
              </div>
            </div>
          </div>
        </section>
        <footer className="bg-gray-900">
          <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
            <div className="flex justify-center gap-x-6 md:order-2"></div>
            <p className="mt-8 text-center text-sm/6 text-gray-400 md:order-1 md:mt-0">
              &copy; {new Date().getFullYear()} MEMiz, Commerce & Industries
              Department, Govt. of Mizoram. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}
