export default function Pms() {
  const styles = {
    aspectRatio: "801/1036",
    width: "50.0625rem",
    background: "linear-gradient(to top right, #ff80b5, #9089fc)",
    opacity: 0.3,
    clipPath:
      "polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)",
  };
  return (
    <div className="">
      <div className="relative isolate overflow-hidden bg-white py-10 sm:py-10">
        <div
          className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
          aria-hidden="true"
        >
          <div
            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            style={styles}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <p className="text-lg font-semibold leading-8 tracking-tight text-red-600">
              PMS
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              PMS ṭhatna leh thil tum
            </h1>
            <ul role="list" className="mt-5 max-w-xl space-y-4 text-gray-600">
              <li className="flex gap-x-3">
                <span>
                  Sumdawngte tan inphochhuahna remchang a siamsak a. Ram chhung
                  (National) leh ram pawn (International) ah Trade Fairs,
                  Exhibition, MSME Expo leh adt., a buatsaih sak thin a ni.
                </span>
              </li>
              <li className="flex gap-x-3">
                <span>
                  MSME-te tan packaging lama hmasawnna tur a hriattir thin.
                </span>
              </li>
              <li className="flex gap-x-3">
                <span>
                  Thil siam lak luh leh thawn chhuah dan, GeM (Government e
                  Marketplace) portal chungchang, MSME Conclave chungchang leh
                  international/national a sumdawnna hmakhua hrang hrangte
                  sumdawngten an hriat theih phah thin.
                </span>
              </li>
            </ul>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
            <div className="relative lg:order-last lg:col-span-5">
              <svg
                className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                    width="200"
                    height="200"
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M0.5 0V200M200 0.5L0 0.499983" />
                  </pattern>
                </defs>
                <rect
                  width="100%"
                  height="100%"
                  stroke-width="0"
                  fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)"
                />
              </svg>
              <figure className="border-l border-red-600 pl-3 sm:pl-8">
                <blockquote className="text-lg font-normal leading-8 tracking-tight text-gray-900">
                  <p className="font-extrabold mt-3">
                    II. Theihna tihpun (Capacity building)
                  </p>

                  <ul
                    role="list"
                    className="mt-4 max-w-xl space-y-4 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      <span>
                        (A) Packaging ṭha leh tunlai zawk kawhhmuh - Cluster
                        huapa packaging leh branding lama hmasawnna kalpuina:
                        Sanction a tam berah cheng nuai 15.
                      </span>
                    </li>

                    <li className="flex gap-x-3">
                      <span>
                        (B) Bar Code hmang tura Unit tin te hmasawnna kawng
                        kawhhmuh: One-time registration atan 80% sum leh pai
                        ṭanpuina pek. Bar code 100 nos emaw cheng ₹50,650/- a
                        tlem zawk zawk hi sumdawngte hnenah annual recurring fee
                        (a hmasa kum thum atan) atan a peksak thei ang.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        (C) Internet a sumdawnna zauh. Sum sen atanga 75% (a tam
                        berah ₹25,000/-) ṭanpuina pek. Udyam Registration nei
                        Micro Enterprise ten an dil thei ang.
                      </span>
                    </li>

                    <li className="flex gap-x-3">
                      <span>
                        (D) Rampum huap Workshops / Seminars. Ṭanpui theih chin:
                        Hetiang lama thiamna bik nei (kum thum tal hna lo thawk
                        tawh) tan ramchhung khawi hmunah pawh workshop/seminar
                        an neih a nuai ₹5.0 chin tanpuina pek.
                      </span>
                    </li>

                    <li className="flex gap-x-3">
                      <span>
                        (E) Ministry/Office of DC (MSME) ten ram pum huap
                        Workshop/seminar buatsaih. (Conventional/Virtual)
                      </span>
                    </li>
                  </ul>

                  <figure className="mt-10 border-l border-red-600 pl-3 sm:pl-9">
                    <blockquote className="font-semibold text-gray-900">
                      <p>
                        Hrechiang duh tan ahnuaia link tarlanah hian a en theih
                        ang
                        <a
                          href="https://cluster.dcmsme.gov.in/"
                          className="text-red-600 pl-2"
                        >
                          https://cluster.dcmsme.gov.in/
                        </a>
                      </p>
                    </blockquote>
                  </figure>
                </blockquote>
              </figure>
            </div>
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
              <p className="font-bold text-xl">SCHEME in a ken te</p>
              <p className="font-extrabold mt-3">
                I. Ram chhunga sumdawnna zauh zel tura ṭanpuina:
              </p>
              <ul role="list" className="mt-4 max-w-xl space-y-4 text-gray-600">
                <span className="text-xs font-extrabold">
                  (A) Trade Fair/Exhibition a tel MSE-te tan an hmunhma hman
                  man- 100% subsidy
                </span>
                <li className="flex gap-x-3">
                  <span>
                    Sum mamawhna dang (Kal man telin) 100% (emaw ₹25,000 chin)
                    tumsak
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    A tam berah cheng nuai 1.50 (A-className khawpui lian) leh
                    cheng nuai 0.80 (khawpui te deuh ah)
                  </span>
                </li>
              </ul>

              <ul role="list" className="mt-4 max-w-xl space-y-4 text-gray-600">
                <span className="text-xs mt-8 font-extrabold">
                  (B) Ram chhung (Domestic) leh ram pawn (international) Trade
                  fair/exhibition Ministry/Office of DC (MSME)/Government
                  Organisation in a huaihawt ang a, Empowered Committee in sum
                  hman tur zat a bituk ang.
                </span>
                <li className="flex gap-x-3">
                  <span>
                    Virtual Trade Fair buatsaih nan cheng nuai 16.45 thleng a
                    hmang thei ang.
                  </span>
                </li>
              </ul>

              <ul role="list" className="mt-4 max-w-xl space-y-4 text-gray-600">
                <span className="text-xs mt-8 font-extrabold">
                  (C) Vendor Development Program (VDP): MSE-te siam chhuah
                  pholan nan VDP a buatsaih theih a, Central Public Sector
                  Enterprises (CPSE) level a VDP buatsaih ah program khat zelah
                  cheng nuai 5 zel (Programme hun chhung hi 2 days tur a ni)
                </span>
              </ul>

              <p className="font-bold mt-8 text-xl">
                III. Bungraw hralhna tur dawr (retail) siam
              </p>
              <ul role="list" className="mt-4 max-w-xl space-y-4 text-gray-600">
                <li className="flex gap-x-3">
                  <span>
                    GI (Geographical Indication) Products chawikang tura hmunhma
                    changtlung tak (Retail Outlets) siam. (Sumdawngten 50% an
                    thawh ve a ngai ang a, an thil siam zinga GI tag nei chaih
                    an zuar thei ang)
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    Khawpui lian (Metro & A-className) ah nuai ₹30 chin, khawpui
                    dang ah nuai ₹20 chin a pe thei ang a, 500sq.ft bial hmun ah
                    bungraw ṭha tak taka thuam a ni tur a ni.
                  </span>
                </li>
              </ul>
              <div className="mt-10 mb-5 text-xl font-bold text-center">
                Empowered Committee (Composition)
              </div>
              <div className="flex flex-row w-full">
                <div className=" mt-5">
                  <div className="flex flex-row">
                    <div className="w-1/2 font-semibold">
                      Secretary / AS&DC (MSME)
                    </div>
                    <div className="w-1/2 pl-10 sm:pl-20">Chairman</div>
                  </div>
                  <div className="flex flex-row mt-5">
                    <div className="w-1/2 font-semibold">
                      Joint Secretary, (SME)
                    </div>
                    <div className="w-1/2 pl-10 sm:pl-20">Representative</div>
                  </div>
                  <div className="flex flex-row mt-5">
                    <div className="w-1/2 font-semibold">
                      ADC (PMS) / In-charge of PMS Division; Director (Planning
                      and Marketing) NSIC; Nominee of IFW
                    </div>
                    <div className="w-1/2 pl-10 sm:pl-20">Members</div>
                  </div>
                  <div className="flex flex-row mt-5">
                    <div className="w-1/2 font-semibold">
                      Export promotion Association / Industry Association
                    </div>
                    <div className="w-1/2 pl-10 sm:pl-20">
                      Special Invitee (optional)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
