const people = [
  {
    name: "Nuai 0-10",
    title: "0.37",
    email: "0.33",
  },
  {
    name: "Nuai 10-50",
    title: "0.55",
    email: "0.50",
  },
  {
    name: "Nuai 50-Vbc.1",
    title: "0.60",
    email: "0.54",
  },
  {
    name: "Vbc 1-2",
    title: "1.20",
    email: "1.08",
  },
  {
    name: "Vbc 2-5",
    title: "1.35",
    email: "1.22",
  },
  // More people...
];
const next = [
  {
    name: "Micro Enterprises",
    title: "85%",
    email: "75%",
    a: "75%",
  },
  {
    name: " North-East huamchhung MSE",
    namea: "(Sikkim, UT of Jammu & Kashmir and UT of Ladakh a huam tel)",
    title: "80%",
    email: "80%",
    a: "75%",
  },
  {
    name: "85%",
    title: "-",
    email: "-",
  },
  {
    name: "75%",
    title: "-",
    email: "-",
  },
];
export default function Cgstmse() {
  return (
    <div className="">
      <div className="bg-white px-6 py-10 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-red-600">
            Sawifiahna
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            CGTMSE chu eng nge ni?
          </h1>
          <p className="mt-6 text-xl leading-8">
            He scheme hi Credit Guarantee Fund Scheme for Micro and Small
            Enterprises (CGS-I) ti a hriat ṭhin a ni a [he mi hma hian Credit
            Guarantee Fund Scheme for Small Industries (CGFSI) ti a hriat a ni
            bawk]. Hemi hnu hian MSMED Act-2006 hnuaiah dan ang takin Trust
            hming hi siamṭhain Credit Guarantee Fund Trust for Micro and Small
            Enterprises vuah a ni a, a scheme chuan Credit Guarantee Fund Scheme
            for Micro and Small Enterprises tih a lo ni ta a ni.
          </p>
          <div className="mt-10 max-w-2xl">
            <h3 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
              Scheme thil tum
            </h3>
            <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-red-600"
                  aria-hidden="true"
                /> */}
                <span>
                  Sumdawnna a bulṭan tak tak (a bikin loan vawikhat lakna tur-
                  First generation entrepreneurs) te’n a mahni thawhrimna hmang
                  ngeia lei rem rap tur a Loan dahkham tlem te ( a ṭhenah
                  dahkham awm lo) chauh hmanga an lak theih nana puih a ni.
                </span>
              </li>
            </ul>

            <div className="mt-12 max-w-2xl">
              <h3 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                Scheme huamchin
              </h3>
              <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Sumdawnna a bulṭan tak tak (a bikin loan vawikhat lakna tur-
                    First generation entrepreneurs) te’n a mahni thawhrimna
                    hmang ngeia lei rem rap tur a Loan dahkham tlem te ( a
                    ṭhenah dahkham awm lo) chauh hmanga an lak theih nana puih a
                    ni.
                  </span>
                </li>
              </ul>
            </div>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              Annual Guarantee Fee chungchang
            </h2>
            <strong className="font-semibold text-gray-900">
              Kum tin Guarantee Fee hi pek a ngai a, he fee hian loan pe
              chhuaktute tan invenna a pe ve a ni. A rate erawh kan loan lak zat
              a zir a siam rem a ni, a hnuai ami ang hian
            </strong>
            <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
              <strong className="font-semibold text-gray-900">
                Loan kalhmang
              </strong>
              <li className="flex gap-x-2">
                {/* <CheckCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-red-600"
                  aria-hidden="true"
                /> */}
                <span>
                  A thar(new) emaw lo awm tawh(existing) emaw Micro leh Small
                  Lending hmanga credit facility pek tawhte institution-ah hian
                  collateral security leh/ emaw third party guarantee engmah a
                  awm lo.
                </span>
              </li>
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-red-600"
                  aria-hidden="true"
                /> */}
                <span>
                  He scheme hmang vek a Loan dil nawn tum tan Manufacturing leh
                  Service sector ah chuan a kalphung/kalhmang hlui ang chiaha
                  dil leh theih a ni. Hei hian trade sector a huam lo thung.
                </span>
              </li>
            </ul>
            <figure className="mt-10 border-l border-red-600 pl-2 sm:pl-9">
              <blockquote className="font-semibold text-gray-900">
                <p>
                  Hrechiang duh tan ahnuaia link tarlanah hian a en theih ang
                  <a
                    href="https://clcss.dcmsme.gov.in/"
                    className="text-red-600 pl-2"
                  >
                    https://clcss.dcmsme.gov.in/
                  </a>
                </p>
              </blockquote>
            </figure>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Fee Rate
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Standard Rate (%)
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Fee Rate After Discount (10%)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {people.map((person) => (
                        <tr key={person.email}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {person.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.title}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.email}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.role}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-5">
              <div className="row align-items-center">
                <table id="fees_table">
                  <tbody>
                    <tr>
                      <td colspan="3" className="wide-box">
                        <ul>
                          <li>
                            <p>Additional concession hi 10% a ni</p>
                          </li>
                          <li>
                            <p>
                              A kum khatnaah chuan sum intiamkam zat (guaranteed
                              amount) atanga AGF hi chhut tur a ni a. A kum dang
                              chu a sum intiamkam bang aṭanga chhut zel tur a
                              ni.
                            </p>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className=" mt-20 font-bold text-2xl">
              Category (sumdawnna huang hrang hrangte huamin)
            </div>
            <div className=" mt-10 font-semibold text-xl">
              Loan lak zat chin aṭanga Guarantee (tiamkam) hmuh theih zat
            </div>
            <div className="sm:hidden">
              <div className=" grid grid-cols-1 mt-5">
                <div className="font-bold text-xl">Micro Enterprises</div>
                <div className="grid grid-cols-3">
                  <div className="font-semibold">Nuai 5 chin</div>
                  <div className=" font-semibold ml-3">Nuai 50 chin</div>
                  <div className="font-semibold ml-3">Vbc. 5 chin</div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="font-extralight">85%</div>
                  <div className="font-extralight ml-3">75%</div>
                  <div className="font-extralight ml-3">75%</div>
                </div>
              </div>
              <div className=" grid grid-cols-1 mt-5">
                <div className="font-bold text-xl">
                  North-East huamchhung MSE (Sikkim, UT of Jammu & Kashmir and
                  UT of Ladakh a huam tel)
                </div>
                <div className="grid grid-cols-3">
                  <div className="font-semibold">Nuai 5 chin</div>
                  <div className=" font-semibold ml-3">Nuai 50 chin</div>
                  <div className="font-semibold ml-3">Vbc. 5 chin</div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="font-extralight">80%</div>
                  <div className="font-extralight ml-5">80%</div>
                  <div className="font-extralight ml-5">75%</div>
                </div>
              </div>
              <div className=" grid grid-cols-1 mt-5">
                <div className="font-bold text-xl">
                  Hmeichhia entrepreneurs/SC/ST entrepreneurs/Aspirational
                  District a awm te/ZED certified/Rualban lo(PwD)/MSE promoted
                  by Agniveers
                </div>
                <div className="grid grid-cols-3">
                  <div className="font-semibold">Nuai 5 chin</div>
                  <div className=" font-semibold ml-3">Nuai 50 chin</div>
                  <div className="font-semibold ml-3">Vbc. 5 chin</div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="font-extralight">85%</div>
                  <div className="font-extralight ml-5">-</div>
                  <div className="font-extralight ml-5">-</div>
                </div>
              </div>
              <div className=" grid grid-cols-1 mt-5">
                <div className="font-bold text-xl">
                  Kawng dang hmang a loan la tu
                </div>
                <div className="grid grid-cols-3">
                  <div className="font-semibold">Nuai 5 chin</div>
                  <div className=" font-semibold ml-3">Nuai 50 chin</div>
                  <div className="font-semibold ml-3">Vbc. 5 chin</div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="font-extralight">75%</div>
                  <div className="ml-5 font-extralight">-</div>
                  <div className=" ml-5 font-extralight">-</div>
                </div>
              </div>
            </div>
            <div className="hidden sm:block">
              <div className=" grid grid-cols-2 mt-5">
                <div className=" font-semibold"></div>
                <div className="grid grid-cols-3">
                  <div className="font-semibold ml-5">Nuai 5 chin</div>
                  <div className=" font-semibold ml-3">Nuai 50 chin</div>
                  <div className="font-semibold ml-3">Vbc. 5 chin</div>
                </div>
              </div>
              <div className="">
                <div className=" grid grid-cols-2 mt-5">
                  <div className="font-semibold">Micro Enterprises</div>
                  <div className="grid grid-cols-3">
                    <div className="ml-5">85%</div>
                    <div className="ml-3">75%</div>
                    <div className=" ml-3">75%</div>
                  </div>
                </div>
                <div className=" grid grid-cols-2 mt-5">
                  <div className=" font-semibold">
                    North-East huamchhung MSE (Sikkim, UT of Jammu & Kashmir and
                    UT of Ladakh a huam tel)
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="ml-5">80%</div>
                    <div className="ml-3">80%</div>
                    <div className=" ml-3">75%</div>
                  </div>
                </div>
                <div className=" grid grid-cols-2 mt-5">
                  <div className="font-semibold">
                    Hmeichhia entrepreneurs/SC/ST entrepreneurs/Aspirational
                    District a awm te/ZED certified/Rualban lo(PwD)/MSE promoted
                    by Agniveers
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="ml-5">85%</div>
                    <div className="ml-3">-</div>
                    <div className=" ml-3">-</div>
                  </div>
                </div>
                <div className=" grid grid-cols-2 mt-5">
                  <div className="font-semibold">
                    Kawng dang hmang a loan la tu
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="ml-5">75%</div>
                    <div className="ml-3">-</div>
                    <div className=" ml-3">-</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
